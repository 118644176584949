import React, { useState, useCallback } from 'react';
import { 
  DataGrid, 
  GridToolbar,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  useTheme,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Paper,
  Divider,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon, Visibility, VisibilityOff, Key as KeyIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';

const dataTypes = [
  'string',
  'number',
  'boolean',
  'null',
];

const commonApiKeyNames = [
  'x-api-key',
  'Authorization',
  'api-key',
  'x-api-token',
  'x-rapidapi-key',
  'apikey',
  'CUSTOM'
];

const ToolManagementGrid = ({ data, isLoading, onAddTool, onUpdateTool, onDeleteTools }) => {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [currentTool, setCurrentTool] = useState({
      name: '', description: '', apiEndpoint: '', apiKeyName: '', apiKeyValue: '', includeInHeader: false, llmVersion: '', 
      publicLocal: '', enviornment: '', lastTested: null, lastTrained: null, 
      lastCheckedForBias: null, testingDetails: '', trainingDetails: '', 
      biasDetails: '', rightsImpact: '', safteyImpact: '', parameters: [],
      outputs: [],
      //apiKey: { name: '', value: '', includeInHeader: false }
    });
    const [showApiKey, setShowApiKey] = useState(false);
    const [isCustomApiKeyName, setIsCustomApiKeyName] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedParameter, setExpandedParameter] = useState(null);
  
    const handleOpenDialog = useCallback((tool = {
      name: '', description: '', apiEndpoint: '', apiKeyName: '', apiKeyValue: '', includeInHeader: false, llmVersion: '', 
      publicLocal: '', enviornment: '', lastTested: null, lastTrained: null, 
      lastCheckedForBias: null, testingDetails: '', trainingDetails: '', 
      biasDetails: '', rightsImpact: '', safteyImpact: '', parameters: [],
      outputs: [],
      //apiKey: { name: '', value: '', includeInHeader: true }
    }, edit = false) => {
      setCurrentTool({...tool,
        lastTested: tool.lastTested ? parseISO(tool.lastTested) : null,
        lastTrained: tool.lastTrained ? parseISO(tool.lastTrained) : null,
        lastCheckedForBias: tool.lastCheckedForBias ? parseISO(tool.lastCheckedForBias) : null,
        parameters: Array.isArray(tool.parameters)? tool.parameters : [],
        //outputVariable: tool.outputVariable || '',
        outputs: Array.isArray(tool.outputs) ? tool.outputs : [],
        //apiKey: tool.apiKey || { name: '', value: '', includeInHeader: true }
    });
      setIsCustomApiKeyName(tool.apiKeyName ? !commonApiKeyNames.includes(tool.apiKeyName) : false);
      setIsEditing(edit);
      setOpenDialog(true);
    }, []);
  
    const handleCloseDialog = useCallback(() => {
      setOpenDialog(false);
      setCurrentTool({
        name: '', description: '', apiEndpoint: '', apiKeyName: '', apiKeyValue: '', includeInHeader: false, llmVersion: '', 
        publicLocal: '', enviornment: '', lastTested: null, lastTrained: null, 
        lastCheckedForBias: null, testingDetails: '', trainingDetails: '', 
        biasDetails: '', rightsImpact: '', safteyImpact: '', parameters: [],
        outputs: [],
        //apiKey: { name: '', value: '', includeInHeader: false }
      });
      setIsEditing(false);
    }, []);
  
    const handleInputChange = useCallback((event) => {
      const { name, value } = event.target;
      if (name === 'includeInHeader') {
        const newVal = event.target.checked;
        setCurrentTool((prevTool) => ({
          ...prevTool,
          [name]: newVal,
        }));
      } else {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        [name]: value,
      }));
    }
    }, []);

    const handleDateChange = useCallback((name, date) => {
        setCurrentTool((prevTool) => ({
          ...prevTool,
          [name]: date,
        }));
      }, []);

    const handleApiKeyNameChange = useCallback((event) => {
      const selectedValue = event.target.value;
      setIsCustomApiKeyName(selectedValue === 'CUSTOM');
      setCurrentTool((prevTool) => ({
        ...prevTool,
        apiKey: {
          ...prevTool.apiKey,
          name: selectedValue === 'CUSTOM' ? '' : selectedValue
        }
      }));
    }, []);

    const handleCustomApiKeyNameChange = useCallback((event) => {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        apiKey: {
          ...prevTool.apiKey,
          name: event.target.value
        }
      }));
    }, []);  

    const handleApiKeyChange = useCallback((field, value) => {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        apiKey: { ...prevTool.apiKey, [field]: value }
      }));
    }, []);

    const handleToggleApiKeyVisibility = useCallback(() => {
      setShowApiKey((prev) => !prev);
    }, []);  

    const handlePairChange = useCallback((index, field, value) => {
      setCurrentTool((prevTool) => {
        const newPairs = [...prevTool.parameters];
        newPairs[index] = { ...newPairs[index], [field]: value };
        return { ...prevTool, parameters: newPairs };
      });
    }, []);
    
    const addPair = useCallback(() => {
      setCurrentTool((prevTool) => {
        const newParameters = [
          ...prevTool.parameters,
          { parameter: '', type: 'string', description: '', required: false, value: '', isInBody: false }
        ];
        const newIndex = newParameters.length - 1;
        setExpandedParameter(`panel${newIndex}`);
        return { ...prevTool, parameters: newParameters };
      });
    }, []);
    
    const removePair = useCallback((index) => {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        parameters: prevTool.parameters.filter((_, i) => i !== index)
      }));
    }, []);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpandedParameter(isExpanded ? panel : null);
    };

    const handleOutputChange = useCallback((index, value) => {
      setCurrentTool((prevTool) => {
        const newOutputs = [...prevTool.outputs];
        newOutputs[index] = value;
        return { ...prevTool, outputs: newOutputs };
      });
    }, []);

    const addOutput = useCallback(() => {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        outputs: [...prevTool.outputs, '']
      }));
    }, []);

    const removeOutput = useCallback((index) => {
      setCurrentTool((prevTool) => ({
        ...prevTool,
        outputs: prevTool.outputs.filter((_, i) => i !== index)
      }));
    }, []);
  
    const handleSaveTool = useCallback(() => {
        const toolToSave = {
            ...currentTool,
            lastTested: currentTool.lastTested ? format(currentTool.lastTested, 'yyyy-MM-dd') : null,
            lastTrained: currentTool.lastTrained ? format(currentTool.lastTrained, 'yyyy-MM-dd') : null,
            lastCheckedForBias: currentTool.lastCheckedForBias ? format(currentTool.lastCheckedForBias, 'yyyy-MM-dd') : null,
            parameters: currentTool.parameters || []
          };
      if (isEditing) {
        onUpdateTool(toolToSave);
      } else {
        onAddTool(toolToSave);
      }
      handleCloseDialog();
    }, [currentTool, isEditing, onUpdateTool, onAddTool, handleCloseDialog]);
  
    const handleDeleteSelected = useCallback(() => {
      onDeleteTools(selectedRows);
      setSelectedRows([]);
    }, [selectedRows, onDeleteTools]);

    const columns = [
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "llmVersion",
          headerName: "LLM",
          flex: 1.5,
        },
        {
          field: "publicLocal",
          headerName: "Host",
          flex: .6,
        },
        {
          field: "enviornment",
          headerName: "Environment",
          flex: 1,
        },
        {
          field: "lastTested",
          headerName: "Tested",
          flex: .80,
          valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
        },
        {
          field: "lastTrained",
          headerName: "Trained",
          flex: .80,
          valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
        },
        {
          field: "lastCheckedForBias",
          headerName: "Bias Check",
          flex: .80,
          valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
        },
        {
            field: "rightsImpact",
            headerName: "Impact on Rights",
            flex: 2,
        },
        {
            field: "safteyImpact",
            headerName: "Impact on Safety",
            flex: 3,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleOpenDialog(params.row, true)}
              />,
            ],
          },
    
      ];
return (
    <div>
        <Box sx={{ mt: "30px", display: 'flex', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Add New Tool
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleDeleteSelected}
          disabled={selectedRows.length === 0}
        >
          Delete Selected
        </Button>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.id}
          rows={(data) || []}
          columns={columns}
          checkboxSelection
        onSelectionModelChange={setSelectedRows}
        selectionModel={selectedRows}
          components={{
            ColumnMenu: CustomColumnMenu,
            Toolbar: GridToolbar,
          }}
          onRowClick={(params) => handleOpenDialog(params.row, true)} 
        />
      </Box>
      <Dialog 
        open={openDialog} onClose={handleCloseDialog} 
        sx={{
            '& .MuiDialog-paper': {
              backgroundColor: theme.palette.secondary[900], // Change the background color here
              width: '80%',
              maxWidth: 'none',
            },
          }}
        >
        <DialogTitle>{isEditing ? 'Edit Tool' : 'Add New Tool'}</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Name"
            value={currentTool.name}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
              name="description"
              label="Description"
              value={currentTool.description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
          />
          <TextField
              name="apiEndpoint"
              label="API Endpoint"
              value={currentTool.apiEndpoint}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
          />
          <Box sx={{ mt: 3, mb: 2 }}>
                        <Paper 
                            variant="outlined" 
                            sx={{ 
                                p: 2,
                                backgroundColor: theme.palette.secondary[750],
                                border: `1px solid ${theme.palette.secondary[600]}`,
                            }}
                        >
                            <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1, 
                                mb: 2 
                            }}>
                                <KeyIcon color="primary" />
                                <Typography variant="h6" component="div">
                                    API Key Configuration
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ ml: 1 }}
                                >
                                    (Optional)
                                </Typography>
                            </Box>
                            
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 2
                            }}>
                                <FormControl fullWidth>
                                    <InputLabel id="api-key-name-label">API Key Name</InputLabel>
                                    <Select
                                        labelId="api-key-name-label"
                                        name='apiKeyName'
                                        value={isCustomApiKeyName ? 'CUSTOM' : currentTool.apiKeyName}
                                        onChange={handleInputChange}
                                        label="API Key Name"
                                    >
                                        {commonApiKeyNames.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {isCustomApiKeyName && (
                                    <TextField
                                        label="Custom API Key Name"
                                        name='apiKeyName'
                                        value={currentTool.apiKeyName}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                )}

                                <TextField
                                    label="API Key Value"
                                    name='apiKeyValue'
                                    type={showApiKey ? 'text' : 'password'}
                                    value={currentTool.apiKeyValue}
                                    onChange={handleInputChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle api key visibility"
                                                    onClick={() => setShowApiKey(!showApiKey)}
                                                    edge="end"
                                                >
                                                    {showApiKey ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                
                                <FormControlLabel
                                    sx={{ mt: 1 }}
                                    control={
                                        <Checkbox
                                            name='includeInHeader'
                                            checked={currentTool.includeInHeader}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            Include in HTTP Header
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Paper>
                    </Box>
          <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
                      Parameters
                  </Typography>
                  {(currentTool.parameters || []).map((pair, index) => (
                    <Accordion 
                    key={index}
                    expanded={expandedParameter === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                    sx={{ mb: 2, backgroundColor: theme.palette.secondary[750] }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Parameter {index + 1}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{pair.parameter || 'Unnamed parameter'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2, gap: 2  }}>
                          <TextField
                              label="Parameter"
                              value={pair.parameter}
                              onChange={(e) => handlePairChange(index, 'parameter', e.target.value)}
                              sx={{ flex: '1 1 200px' }}
                          />
                          <TextField
                              label="Description"
                              value={pair.description}
                              onChange={(e) => handlePairChange(index, 'description', e.target.value)}
                              sx={{ flex: '1 1 200px' }}
                          />
                          <FormControl sx={{ flex: '1 1 150px' }}>
                              <InputLabel>Type</InputLabel>
                              <Select
                                  value={pair.type || 'string'}
                                  onChange={(e) => handlePairChange(index, 'type', e.target.value)}
                                  label="Type"
                              >
                                  {dataTypes.map((type) => (
                                      <MenuItem key={type} value={type}>
                                          {type}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                          <TextField
                              label="Default Value"
                              value={pair.value}
                              onChange={(e) => handlePairChange(index, 'value', e.target.value)}
                              sx={{ flex: '1 1 200px' }}
                          />
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={pair.required}
                                      onChange={(e) => handlePairChange(index, 'required', e.target.checked)}
                                  />
                              }
                              label="Required"
                          />

                          <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pair.isInBody}
                                        onChange={(e) => handlePairChange(index, 'isInBody', e.target.checked)}
                                    />
                                }
                                label="Add to Body"
                            />
                          <IconButton onClick={() => removePair(index)}>
                              <DeleteIcon />
                          </IconButton>
                      </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  <Button 
                      startIcon={<AddIcon />} 
                      onClick={addPair}
                      sx={{ mt: 2 }}
                  >
                      Add Parameter
                  </Button>
            <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
                Outputs
            </Typography>
            {currentTool.outputs.map((output, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <TextField
                        label={`Output ${index + 1}`}
                        value={output}
                        onChange={(e) => handleOutputChange(index, e.target.value)}
                        required
                        fullWidth
                    />
                    <IconButton onClick={() => removeOutput(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addOutput} sx={{ mt: 2, mb: 2 }}>
                Add Output
            </Button>
            <TextField
                name="llmVersion"
                label="LLM Version"
                value={currentTool.llmVersion}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                name="publicLocal"
                label="Host"
                value={currentTool.publicLocal}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                name="enviornment"
                label="Environment"
                value={currentTool.enviornment}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
            <DatePicker
              label="Last Tested"
              value={currentTool.lastTested}
              onChange={(date) => handleDateChange('lastTested', date)}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <DatePicker
              label="Last Trained"
              value={currentTool.lastTrained}
              onChange={(date) => handleDateChange('lastTrained', date)}
              slotProps={{ textField: { fullWidth: true } }}
            />
            <DatePicker
              label="Last Checked for Bias"
              value={currentTool.lastCheckedForBias}
              onChange={(date) => handleDateChange('lastCheckedForBias', date)}
              slotProps={{ textField: { fullWidth: true } }}
            />
            </Box>
          </LocalizationProvider>
            <TextField
                name="testingDetails"
                label="Testing Details"
                value={currentTool.testingDetails}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
            />
            <TextField
                name="trainingDetails"
                label="Training Details"
                value={currentTool.trainingDetails}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
                margin="normal"
            />
            <TextField
                name="biasDetails"
                label="Bias Details"
                value={currentTool.biasDetails}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
                margin="normal"
            />
            <TextField
                name="rightsImpact"
                label="Impact on Rights"
                value={currentTool.rightsImpact}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
                margin="normal"
            />
            <TextField
                name="safteyImpact"
                label="Impact on Safety"
                value={currentTool.safteyImpact}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveTool} color="primary">
          {isEditing ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
);
}

export default ToolManagementGrid;