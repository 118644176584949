import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "sections/layout";
import Chat from "sections/chat";
import Dashboard from "sections/dashboard";
import Users from "sections/users";
import Logs from "sections/logs";
import Admin from "sections/admin";
import Trends from "sections/trends";
import Signin from "sections/signin";
import Tools from "sections/tools";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AuthRedirectHandler from "contexts/AuthRedirectHAndler";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  //var userId = useSelector((state) => state.global.userId);
  //const { data, isLoading  } = useGetUserQuery(userId);
  console.log("user  ~ data App:")

  return (
    <div className="app">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthRedirectHandler />
        <Routes>
          <Route element={<Layout />}>
          <Route path="/" element={
              <>
                <AuthenticatedTemplate>
                  <Chat />
                {/* {(() => {
                      if (isLoading) {
                        return <div>Loading...</div>;
                      }
                      if (data) {
                        return <Chat user={data.name} />;
                      }
                      return <div>User data not available</div>;
                    })()} */}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Signin />
                </UnauthenticatedTemplate>
              </>
            } />
            <Route path="/dashboard" element={
              <>
                <AuthenticatedTemplate>
                  <Dashboard />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            } />
            <Route
              path="/chat"
              element={
                <>
                  <AuthenticatedTemplate>
                    <Chat />
                    {/* {(() => {
                      if (isLoading) {
                        return <div>Loading...</div>;
                      }
                      if (data) {
                        return <Chat user={data.name} />;
                      }
                      return <div>User data not available</div>;
                    })()} */}
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Navigate to="/" replace />
                  </UnauthenticatedTemplate>
                </>
              }
            />
            <Route path="/users" element={
              <>
                <AuthenticatedTemplate>
                  <Users />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            } />
            <Route path="/logs" element={
              <>
                <AuthenticatedTemplate>
                  <Logs />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            } />
            <Route path="/trends" element={
              <>
                <AuthenticatedTemplate>
                  <Trends />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            } />
            <Route path="/tools" element={
              <>
                <AuthenticatedTemplate>
                  <Tools />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            } />
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </div>
  );
}

export default App;
