import React, { useState } from 'react';
import { 
  Button, 
  Menu, 
  MenuItem, 
} from '@mui/material';
import RoleDialog from './RoleDialog';
import ToolAccessDialog from './ToolAccessDialog';

const ActionsDropdown = ({ params }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openAccessDialog, setOpenAccessDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleClick = () => {
    setOpenRoleDialog(true);
    handleClose();
  };

  const handleAccessClick = () => {
    setOpenAccessDialog(true);
    handleClose();
  };
  const handleAccessDialogClose = () => {
    setOpenAccessDialog(false);
    // Optionally, you can trigger a refresh of the user data here if needed
    // For example, if you're using RTK Query, you might want to refetch the user list
    // refetchUsers();
  };
  
  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        ...
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRoleClick}>Change Role</MenuItem>
        <MenuItem onClick={handleAccessClick}>Change Tool Access</MenuItem>
      </Menu>

      <RoleDialog open={openRoleDialog} onClose={() => setOpenRoleDialog(false)} userId={params.row.id} currentRole={params.row.role}/>
      <ToolAccessDialog open={openAccessDialog} onClose={handleAccessDialogClose} userId={params.row.id} />
    </>
  );
};

export default ActionsDropdown;